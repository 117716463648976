import * as React from "react";
import { Table, Space, Button, Popconfirm } from "antd";
import type { ColumnsType } from "antd/es/table";
import { GET_FRANCHISE } from "query";
import { useGetIdentity } from "@pankod/refine-core";
import { EyeOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import AddTypeHead from "./add";

interface FranchiseType {
  key: React.Key;
  id: string;
  franchise_name: string;
  customer_id: string;
  group_name: string;
}

interface AddTypeHeadProps {
    Id: string;
    refetch: () => void;
    Tablerefetch: () => void;
  }

interface FranchiseResponse {
  id: string;
  name: string;
  customer_id: string;
  group_name: string;
}
const handleRefetch = () => {
    console.log("Refetching data...");
    // Add logic to refetch the data here
  };

  const handleTableRefetch = () => {
    console.log("Refreshing table...");
    // Add logic to refresh the table here
  };

  const columns: ColumnsType<FranchiseType> = [
    { title: "Franchise Name", dataIndex: "franchise_name", key: "franchise_name" },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
       
        <Space>
          <AddTypeHead
            Id={record.id} // Pass the id from the current row
            refetch={handleRefetch}
            Tablerefetch={handleTableRefetch}
            
          />
          <Button
            type="primary"
            style={{
              backgroundColor: "green",
              borderColor: "green",
              color: "white",
            }}
            icon={<EyeOutlined />}
            onClick={() => console.log(`Viewing ${record?.id}`)}
          >
            View
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => console.log(`Deleted ${record?.id}`)}
          >
            <Button
              style={{
                backgroundColor: "red",
                borderColor: "red",
                color: "white",
              }}
              icon={<DeleteOutlined />}
            >
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  

type FranchiseProps = {
  org_id: any;
  customer_id: any;
  customer_group_id: any;
};

export const Franchise: React.FC<FranchiseProps> = ({
  org_id,
  customer_group_id,
  customer_id,
}) => {
  const { data: user } = useGetIdentity();
  const userId = user?.metadata?.org_id;
  const [franchiseData, setFranchiseData] = React.useState<FranchiseType[]>([]);

  const getFranchise = async () => {
    try {
      const response: any = await GET_FRANCHISE();
      if (response?.franchise_customer_customer_group_mappings) {
        const mappedData = response.franchise_customer_customer_group_mappings.map(
          (item: FranchiseResponse) => ({
            key: item.id,
            id: item.id,
            franchise_name: item.name,
            customer_id: item.customer_id,
            group_name: item.group_name,
          })
        );
        setFranchiseData(mappedData);
      } else {
        console.warn("No franchise data found");
        setFranchiseData([]);
      }
    } catch (error) {
      console.error("Error fetching franchise data:", error);
    }
  };

  useEffect(() => {
    getFranchise();
  }, []);

  return (
    <Table
      columns={columns}
      expandable={{
        expandedRowRender: (record: any) => (
          <div style={{ padding: 16, backgroundColor: "#fafafa" }}>
            Franchise Name: {record?.franchise_name}
          </div>
        ),
        rowExpandable: (record: any) => !!record?.franchise_name,
      }}
      dataSource={franchiseData}
      bordered
    />
  );
};
