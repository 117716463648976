import {
    Form,
    Input,
    Typography,
    useTable,
} from "@pankod/refine-antd";

import { useGetIdentity } from "@pankod/refine-core";
import React, { useState } from "react";
import { Select, Button, Modal, Space, Descriptions } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useNotification } from "@pankod/refine-core";
import { INSERT_FRANCHISE } from "query";

const { Text } = Typography;
type CreateFranchiseProps = {
    closeCreateModal: () => void;
    createModalVisible: boolean;
    handleFormSubmitted: () => void;
    parentRefetch: any;
};

export const CreateFranchise: React.FC<CreateFranchiseProps> = ({
    handleFormSubmitted,
    closeCreateModal,
  
}) => {
    const { data: user } = useGetIdentity();
    const { open } = useNotification();
    const [model, setModel] = useState(false);
    const [customerId, setCustomerId] = useState<string | null>(null);
    const [form] = Form.useForm();
    console.log(user,"userDetails");
    
    const handleFormSubmit = async () => {
        try {
          
            const values = await form.validateFields();
    
            // const cond = {
            // Object: {
            //         name: values.name,
            //         customer_id: null, 
            //         customer_group_id: null, 
            //         ob_org_id: user?.metadata?.org_id,
                     
            //     },
            // };

            const result = await INSERT_FRANCHISE({Object: {
                name: values.name,
                customer_id: null, 
                customer_group_id: null, 
                ob_org_id: user?.metadata?.org_id,
                description: values?.description || "",
                created_by:user?.id,
                is_active:false

                 
            },}).then(()=> {
                open({
                    type: "success",
                    message: "Franchise created successfully!",
                });
                setModel(false);
                form.resetFields();
            })
    
        } catch (error) {
            open({
                type: "error",
                message: "Failed to create franchise",
            });
        }
    };
    
    

    return (
        <>
            <Modal
                title="Create Franchise"
                onCancel={() => setModel(false)}
                visible={model}
                onOk={handleFormSubmit}
                okText="Create"
            >
                <Form
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                >
                    <Form.Item
                        label="Franchise Name"
                        name="name"
                        rules={[{ required: true, message: "Please enter a name" }]}
                    >
                        <Input placeholder="Name" />
                    </Form.Item>
                    <Form.Item label="Customer">
                        <Select
                            placeholder="Select a customer"
                            value={customerId}
                            onChange={(value) => setCustomerId(value)}
                            style={{ width: "100%" }}
                        >
                            {/* Populate customer options dynamically */}
                            <Select.Option value="customer1">Customer 1</Select.Option>
                            <Select.Option value="customer2">Customer 2</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="description"
                    >
                        <Input.TextArea placeholder="Enter a description (optional)" />
                    </Form.Item>
            
                </Form>
            </Modal>

            <Space style={{ marginTop: "15px" }}>
                <Button
                    type="primary"
                    onClick={() => setModel(true)}
                >
                    <PlusOutlined style={{ marginRight: "5px" }} />
                    Create Franchise
                </Button>
            </Space>
        </>
    );
};
