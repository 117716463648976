import React from "react";
import { nhost } from "utility";
import { Select, Button, Modal, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useNotification } from "@pankod/refine-core";
import { GET_PRODUCTS_NOT_IN_FAVORITE_LIST, INSERT_FAVORITE_LIST_ITEMS } from "query";
 
interface AddTypeHeadProps {
  Id: string;
  refetch: any;
  Tablerefetch: any;
}

const AddTypeHead: React.FC<AddTypeHeadProps> = ({
  Id,
  Tablerefetch,
  refetch,
}) => {
  const { open, close } = useNotification();


  const User:any = nhost.auth.getUser()
  
  
  const [data, setData] = React.useState<any>([]);
  const { Option } = Select;

  const [limit, setLimit] = React.useState<any>(100000);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<any>([]);
  const [searchText, setSearchText] = React.useState<any>(null);
  const [searchCondition, setSearchCondition] = React.useState<any>(null);


  
  
 

  const getInitialData = () => {
   

    GET_PRODUCTS_NOT_IN_FAVORITE_LIST(User?.metadata?.org_id,{
      limit: limit,
      offset: 0,
      favoriteListId: Id,
      where: searchCondition ? { ...searchCondition, products:{ob_org_id:{_eq:User?.metadata?.org_id}} } : {},
    }).then((data: any) => {
 
         const copysSdata: any = data?.data?.get_products_not_in_favorite_list || [];

        setData([...copysSdata]);
      }).catch((error:any)=>{
        console.error("Error =>",error)
        open?.({
          key: "error",
          type: "error",
          message: "",
          description: `Something Went Wrong ${error}`,
        });
        setTimeout(() => {
          close("error");
        }, 5000);
      })
  };

  React.useEffect(() => {
    getInitialData();
  }, [limit]);

 
  React.useEffect(() => {
    return function () {
      return setSelectedValues([]);
    };
  }, []);

 
  const [selectedValues, setSelectedValues] = React.useState<any | null>([]);

  const handleSearch = (newValue: string) => {
    if (newValue) {
      setIsLoading(true);
      GET_PRODUCTS_NOT_IN_FAVORITE_LIST(User?.metadata?.org_id, {
          limit: limit,
          offset: 0,
          favoriteListId: Id,
          where: {is_active: { _eq: true }, name: { _ilike: "%" + newValue?.trim() + "%" } },
        })
        .then((data: any) => {          
          setData(() => []);
          setData(() => [...data?.data?.get_products_not_in_favorite_list]);
          if(data?.data?.get_products_not_in_favorite_list?.length == 0){
            setData([])
          }
          setIsLoading(false);
        }).catch((error:any)=>{
           open?.({
            key: "error",
            type: "error",
            message: "",
            description: `Something Went Wrong ${error}`,
          });
          setTimeout(() => {
            close("error");
          }, 5000);
        })
    } else {
      setData([]);
    }
  };

  const renderNotFoundContent = () => (
    <div style={{display:"flex", justifyContent: "center"}}><Button style={{margin: "10px", backgroundColor: "#4EA144", color: "white", borderRadius: "10px"}}>Add New Category</Button></div>
  );

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleSave = async () => {
    try {
      setIsModalOpen(false);
      const obj = {
        favorite_list_id: Id,
        created_by: localStorage.getItem("user_id")?.replaceAll('"', ""),
      };

      const newData = selectedValues?.map((e: any) => {
        return { ...obj, product_id: e, quantity: 1, ob_org_id: User?.metadata?.org_id, is_active: true };
      });

      console.log(newData, "dsadsasdsadas");
      
 

      const results:any = await INSERT_FAVORITE_LIST_ITEMS( { Object: newData, });
       setSelectedValues([]);
       setSearchCondition(null);
       setSearchText(null)

      getInitialData();
      Tablerefetch();

      refetch();
      open?.({
        key: "success",
        type: "success",
        message: "",

        description: `Added ${results?.data?.insert_favorite_lists_items?.affected_rows} items to the list`,
      });
      setTimeout(() => {
        close("success");
      }, 5000);
    } catch (error) {
      open?.({
        key: "error",
        type: "error",
        message: "",
        description: `Something Went Wrong ${error}`,
      });
      setTimeout(() => {
        close("error");
      }, 5000);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedValues([]);
    setSearchText(null)
  };

  const handleSelectChange = (value: any) => {
    let copyValue: any = [...value]?.filter((e) => e);
    setSelectedValues(copyValue);
  };

 
  React.useEffect(() => {
    if (searchText?.trim()?.length > 0) {
      // "_ilike":"%dry%"
      setSearchCondition({ name: { _ilike: "%" + searchText?.trim() + "%" } });
    } else {
      setSearchCondition(null);
    }
  }, [searchText]);

  const onSelects = (e: any) => {
     if (e) {
      setValue([...value, e]);
    } else {
      setLimit(limit + 5);
    }
  };

  return (
    <span
      onClick={(e) => {
         e?.preventDefault();
        e?.stopPropagation();
      }}
      onChange={(e: any) => {
         setSearchText(e?.target?.value);
        e?.preventDefault();
        e?.stopPropagation();
      }}
    >
      <Button
        type="primary"
        style={{ background: "#42b6f5" }}
        children={<div style={{ display: "flex", alignItems: "center" }}>
              <PlusOutlined style={{ marginRight: "5px" }} />
              Add
            </div>}
            icon={false}
        onClick={(e) => {
          e?.preventDefault();
          e?.stopPropagation();

          showModal();
        }}
      />
      <Modal
        title="Add Items"
        width={"60%"}
        // style={{ top: 20 }}
        visible={isModalOpen}
        okText="Save"
        onOk={handleSave}
        onCancel={handleCancel}
        maskClosable = {false}
      >
        <Select
          mode="multiple"
          showSearch
          style={{ width: "100%" }}
          placeholder="Please select"
          value={selectedValues}
          onChange={handleSelectChange}
          onSelect={onSelects}
          onSearch={handleSearch}
          filterOption={false}
          // notFoundContent={isLoading ? <Spin size="small" /> : null}
          virtual={true}
          id="select"
        >
          {[...data]?.map((e: any, i: any) => {
            return <Option key={e?.id}>{e?.name}</Option>;
          })}

          {/* <Button
            onClick={(e) => {
              setLimit(() => limit + 5);
              e?.preventDefault();
              e?.stopPropagation();
            }}
          >
            {!isLoading ? "Load More..." : <Spin size="small" />}
          </Button> */}
        </Select>
        ,
      </Modal>
    </span>
  );
};

export default AddTypeHead;
